<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>ดาวน์โหลด</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list two-line subheader>
            <v-list-item>{{selectdata}}</v-list-item>
            <v-list-item>{{selectprocess}}</v-list-item>
  
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="close()"
          >{{$t('keydetail.closeDialog')}}</v-btn>
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click="submit()"
          >ตกลง</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show","process_id","process_type_id","selectdata","selectprocess"],
  data: function () {
    return {
      filename: "",
      createprogress: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
         
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closechangename");
        }
      },
    },
  },
  methods: {
      close(){
        this.$emit('close')
      },
      submit(){
           
       var payload = {
         
         account_id_admin: this.dataAccountId,
         process_type_id: "1",
         process_id: "302adf2068d92f122d7af388b5cccae6",
         business_id: this.dataAccountActive.business_info.business_id,
         from_dtm: "20200501",
         to_dtm: "20200901"

       }
    
      },
    calculatesize(parameter) {
      let size;
      if (parameter >= 10000000000000 && parameter <= 1000000000000000) {
        size = (parameter / 10000000000000).toFixed(2) + " TB";
      } else if (parameter >= 1000000000 && parameter <= 1000000000000) {
        size = (parameter / 1000000000).toFixed(2) + " GB";
      } else if (parameter >= 1048576 && parameter <= 1000000000) {
        size = (parameter / 1000000).toFixed(2) + " MB";
      } else if (parameter >= 1000 && parameter <= 1000000) {
        size = (parameter / 1000).toFixed(2) + " KB";
      } else if (parameter === "-") {
        size = parameter;
      } else {
        size = parameter + " B";
      }

      return size;
    },
    calculatedate(parameter) {
      let date = [];
      let month, finalDate;
      if (parameter) {
        date.push(parameter[0] + parameter[1] + parameter[2] + parameter[3]);
        date.push(parameter[4] + parameter[5]);
        date.push(parameter[6] + parameter[7]);
        date.push(parameter[8] + parameter[9]);
        date.push(parameter[10] + parameter[11]);
        date.push(parameter[12] + parameter[13]);
        var months = [
          this.$t("keydetail.month.jan"),
          this.$t("keydetail.month.feb"),
          this.$t("keydetail.month.mar"),
          this.$t("keydetail.month.apr"),
          this.$t("keydetail.month.may"),
          this.$t("keydetail.month.jun"),
          this.$t("keydetail.month.jul"),
          this.$t("keydetail.month.aug"),
          this.$t("keydetail.month.sep"),
          this.$t("keydetail.month.oct"),
          this.$t("keydetail.month.nov"),
          this.$t("keydetail.month.dec"),
        ];
        month = months[date[1] - 1];
        let datebuff =
          date[3] + ":" + date[4] + " " + date[2] + " " + month + " " + date[0];
        finalDate = datebuff;
      } else {
        finalDate = "-";
      }

      return finalDate;
    },
    permission(parameter) {
      // return parameter
      if (
        parameter.view_only === "True" &&
        parameter.download === "True" &&
        parameter.edit === "True"
      ) {
        return "แก้ไขและดาวน์โหลด";
      } else if (
        parameter.view_only === "False" &&
        parameter.download === "True" &&
        parameter.edit === "False"
      ) {
        return "ดาวน์โหลดเท่านั้น";
      } else if (
        parameter.view_only === "True" &&
        parameter.download === "False" &&
        parameter.edit === "False"
      ) {
        return "ดูเท่านั้น";
      } else if (
        parameter.view_only === "True" &&
        parameter.download === "True" &&
        parameter.edit === "False"
      ) {
        return "ดาวน์โหลดเท่านั้น";
      }
    },
  },
};
</script>
<style>
</style>